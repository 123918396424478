import { formatResponse, get, getApiEndpoint, transformApiUrl } from '../helpers/index.js';

class ProductService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'product';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id: '',
        categoryId: '',
        brandId: '',
        genderId: '',
        styleId: '',
        modelId: '',
      },
    });
    return formatResponse(getResponse);
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id,
        categoryId: '',
        brandId: '',
        genderId: '',
        styleId: '',
        modelId: '',
      },
    });
    const [product] = formatResponse(getResponse);
    return product;
  }

  /**
   * Returns products related by passed brand, category, gender, model and style identifier.
   * @param {Object} params
   * @param {string} params.categoryId
   * @param {string} params.brandId
   * @param {string} params.genderId
   * @param {string} params.styleId
   * @param {string} params.modelId
   * @return {Object[]}
   */
  async relatedBy(params) {
    const action = 'read';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
    const endpoint = `${this.apiEndpoint}/${action}`;
    const {
      categoryId = '',
      brandId = '',
      genderId = '',
      styleId = '',
      modelId = '',
    } = params;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', brandId, categoryId, genderId, modelId, styleId },
    }, true);
    return formatResponse(getResponse, true);
  }

  async getPaidTypes() {
    const action = 'paidType';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id: ''
      },
    });
    return formatResponse(getResponse);
  }

  async findPaidTypeById(id) {
    const action = 'paidType';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: {
        id
      },
    });
    const [product] = formatResponse(getResponse);
    return product;
  }
}

export { ProductService as default };
