import React from 'react';
import LoginView from '../views/login/login.view.js';

import { ErpConnect as qad } from '../../services/index.js';

class LoginContainer extends React.Component {
  state = {
    password: '',
    username: '',
    keyboarDimensionClass: '',
    showKeyboard: false,
    message: false,
    button: true,
    keyboard: {
      name: '',
      layout: {},
      display: {},
      data: {},
      value: '',
      component: '',
    },
  };

  keyboard = {
    layouts: {
      spanishLayout: [
        "1 2 3 4 5 6 7 8 9 0 ' ¿ {bksp}",
        'q w e r t y u i o p {enter2}',
        'a s d f g h j k l ñ {enter}',
        'z x c v b n m , . -',
        '.com @ {space}',
      ],
      numericLayout: [
        '1 2 3',
        '4 5 6',
        '7 8 9',
        ' 0 {clear}',
        '{bksp} {enter}',
      ],
    },
    display: {
      '{clear}': 'C',
      '{bksp}': 'Borrar',
      '{enter}': 'Salir del Teclado',
      '{space}': 'Espacio',
      '{enter2}': 'Enter',
    },
  };

  render() {
    return (
      <LoginView
        {...this.state}
        setActiveInput={this.setActiveInput}
        login={this.login}
        setUsername={this.setUsername}
        setPassword={this.setPassword}
        keyboardPress={this.keyboardPress}
      />
    );
  }

  login = async (event) => {
    event.preventDefault();
    this.setState({ button: false });
    let username = document.getElementById('username').value;
    let password = document.getElementById('password').value;

    try {
      const loginResponse = await qad.catalog.salesPerson.login(
        username,
        password
      );
      if ( !loginResponse.agent )
      {
        throw Error("password");
      }
      let user = {
        ...loginResponse, // TODO: Add id attribute to response
        username: loginResponse.agent, // TODO: Change in API agent attribute to id.
        name: loginResponse.name,
        sucursal: loginResponse.site,
        reporte_ventas: parseFloat(loginResponse.salesrep),
        cancel_ped: parseFloat(loginResponse.ordercanc),
      };

      this.setState({ button: true });
      localStorage.setItem('user', JSON.stringify(user));

      let categories = await qad.catalog.category.all();

      categories = categories.map((category) => {

        let urlImg =
          'https://owncloud.miplayera.com.mx/index.php/apps/files_sharing/ajax/publicpreview.php?file=%2F'
          + category.name + '.png' +
          '&c=ebd4bf3451eec8e12ed6231df817d40f&x=267&y=199&t=fIBOKXu7vDsKcUY';

        return {
            ...category,
            imageCloud: urlImg
        }

      });

      const formattedCategories = categories
        .map((category) => {
          return {
            ...category,
            nombre: category.name,
            tipo: 'familia',
          };
        })
        .sort((a, b) => {
          if (a.order) {
            return a.order - b.order;
          } else {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 1;
          }
        });

      localStorage.setItem(
        'formattedCategories',
        JSON.stringify(formattedCategories)
      );

      return this.props.login();
    } catch (err) {
      console.log(err);
      return this.setState({ message: true, button: true });
    }
  };

  setActiveInput = (input, typeOfKeyboard, component, data) => {
    let keyboarDimensionClass = `keyboard-comentarios`;
    let layout;
    let name;

    if (typeOfKeyboard === 'number') {
      layout = this.keyboard.layouts.numericLayout;
      name = 'number';
    } else {
      layout = this.keyboard.layouts.spanishLayout;
      name = 'spanish';
    }

    return this.setState({
      keyboard: {
        display: this.keyboard.display,
        component,
        layout,
        value: data,
        name,
        data,
      },
      showKeyboard: true,
      keyboarDimensionClass,
    });
  };

  keyboardPress = async (data) => {
    let keyboard = Object.assign({}, this.state.keyboard);
    let value;

    if (data === '{bksp}') {
      value = this.state.keyboard.value;

      if (this.state.keyboard.value.length >= 1) {
        value = this.state.keyboard.value.slice(0, -1);
      }
    } else if (data === '{clear}') {
      value = '1';
    } else if (data === '{space}') {
      value = `${this.state.keyboard.value} `;
    } else if (data === '{enter2}') {
      value = `${this.state.keyboard.value} \n`;
    } else if (data === '{enter}') {
      if (
        this.state.keyboard.value === '0' ||
        this.state.keyboard.value === ''
      ) {
        if (keyboard.component === 'pedido') {
          this.cambiarCantidad('1', this.state.keyboard.data);
        } else if (keyboard.component === 'producto') {
          this.setState({ cantidadSeleccionada: '1' });
        }
      }

      return this.setState({
        keyboard: {
          name: '',
          layout: {},
          display: {},
          data: {},
          value: '',
          component: '',
        },
        showKeyboard: false,
      });
    } else {
      value = this.state.keyboard.value + '' + data;
    }

    if (this.state.keyboard.component === 'login-usuario') {
      this.setUsername(value);
    }

    if (this.state.keyboard.component === 'login-password') {
      this.setPassword(value);
    }

    keyboard.value = value;

    return this.setState({
      keyboard,
    });
  };

  setPassword = (password) => {
    this.setState({
      password,
    });
  };

  setUsername = (username) => {
    this.setState({
      username,
    });
  };
}

export { LoginContainer as default };
