import './modal-pedidos.css';

import { Button, Col, Row } from 'react-materialize';

import React from 'react';
import Swal from 'sweetalert2';
import image from '../../../assets/images/imagenotavailable.png';

function ModalPedidosItem(props) {
  let items = props.items;
  let cancelAuth = JSON.parse(localStorage.getItem('user')).orderCanc;
  return (
    <React.Fragment>
      {items.map((item, index) => {
        return (
          <React.Fragment key={item.salesOrderId}>
            <div className="containerPedidoItem">
              <Row>
                <Col s={7} m={7} l={7} xl={7}>
                  <p>
                    <strong># Pedido:</strong> {item.salesOrderId}
                  </p>
                  <p>
                    <strong># Remisión:</strong> {item.remisionId}
                  </p>
                  <p>
                    <strong>Estatus:</strong> {item.status}
                  </p>
                  <p>
                    <strong>Cliente:</strong> {item.customer ? item.customer : ''}
                  </p>
                  <p>
                    <strong>Total: </strong> $
                    {parseFloat(item.total).toFixed(2)}
                  </p>
                </Col>
                <Col s={5} m={5} l={5} xl={5}>
                  <p>
                    <strong># Factura:</strong> {item.invoiceId}
                  </p>
                  <p>
                    <strong>Fecha pedido:</strong> {item.orderDate}
                  </p>
                  <p>
                    <strong>Hora pedido:</strong> {item.orderHour}
                  </p>
                  <p>
                    <strong>Vendedor:</strong>{' '}
                    {item.salesPerson ? item.salesPerson : null}
                  </p>
                  <p>
                    <strong>Sub Total: </strong> $
                    {parseFloat(item.subTotal).toFixed(2)}
                    &nbsp;&nbsp;&nbsp; <strong>IVA: </strong>${item.IVA}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col s={12} m={12} l={12} xl={12} align="center">
                  {cancelAuth === "1" &&
                    (item.status === 'Cancelado' ? null : (
                      <Button
                        node="button"
                        waves="light"
                        style={{ margin: '0 1%' }}
                        onClick={() => {
                          Swal.fire({
                            title: '¿Estas seguro?',
                            text: 'Vas a cancelar el pedido..',
                            icon: 'warning',
                            reverseButtons: true,
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'Salir',
                            confirmButtonText: 'Si, Cancelar',
                          }).then(async (result) => {
                            if (result.value) {
                              let resultCancel = await props.onCancel();
                              if (resultCancel) {
                                Swal.fire(
                                  'Cancelado!',
                                  'Pedido cancelado.',
                                  'success'
                                );
                              }
                            }
                          });
                        }}
                      >
                        Cancelar pedido
                      </Button>
                    ))}
                    <Button
                      node="button"
                      waves="light"
                      style={{ margin: '0 1%' }}
                      onClick={ () => {
                        props.print()
                      }}
                    >
                      Imprimir
                    </Button>
                    <Button
                      node="button"
                      waves="light"
                      style={{ margin: '0 1%' }}
                      onClick={ () => {
                        props.makeRemission(item.salesOrderId)
                      }}
                    >
                      Crear Remision
                    </Button>
                    <Button
                      node="button"
                      waves="light"
                      style={{ margin: '0 1%' }}
                      onClick={ () => {
                        props.showSendMail()
                      }}
                    >
                      Enviar mail
                    </Button>
                    <Button
                      node="button"
                      waves="light"
                      style={{ margin: '0 1%' }}
                      onClick={ () => {
                        props.handleClickCopy()
                      }}
                    >
                      Enviar por Whatsapp
                    </Button>
                    <Button
                      node="button"
                      waves="light"
                      style={{ margin: '0 1%' }}
                      onClick={ () => {
                        props.edit()
                      }}
                    >
                      {( !props.inEdition ) ? 'Modificar' : 'Cerrar'} 
                    </Button>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
export { ModalPedidosItem as default };
