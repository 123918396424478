import './modal-edit-pedidos.css';

import { Button, Col, Row, Checkbox, Preloader } from 'react-materialize';
import React from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

function ModalPedidosEdit(props) {
  let items = props.items;
  let products = props.products;
  return (
    <React.Fragment>
      {items.map((item, index) => {
        let subtotal = 0;
        return (
          <React.Fragment key={item.salesOrderId}>
            <div >
              <table id="table" className="table table-striped edit-product">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Producto</th>
                    <th colSpan="4">
                      <Row>
                        <Col s={3} className="no-padding">
                          Talla
                        </Col>
                        <Col s={4} className="no-padding">
                          Color
                        </Col>
                        <Col s={2} className="no-padding">
                          Cantidad
                        </Col>
                        <Col s={3} className="no-padding">
                          Precio
                        </Col>
                      </Row>
                    </th>
                    <th>Subtotal</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      item.detail.map( (line, i) => {
                      subtotal += ( line.deleted ) ? 0 : parseFloat(parseFloat(line.price)*line.qtyOrd);
                      if ( !line.status ) 
                      {
                        props.changeValue(i, "status", 'N');
                      }
                      return (
                        <tr className={ line.deleted || line.status === 'D' ? "deleted" : "" } key={item.salesOrderId+line.line}>
                          <td>
                            {line.line}
                          </td>
                          <td>
                            {
                              ( !line.productId )
                              ?
                                <AsyncSelect 
                                  cacheOptions
                                  isDisabled={ line.disabled || line.deleted ? true : false }
                                  defaultOptions={ products.slice(0,200) }
                                  loadOptions={props.promiseOptions}
                                  onChange={(item) => props.changeProduct( item, i ) }
                                />
                              :
                              line.product
                            }
                          </td>
                          <td colSpan="4">
                            <Row key={"Data_"+i}>
                              <Col s={3} className="no-padding">
                                { 
                                  ( props.getOptions(line.productId, "sizeOptions").length > 1 ) ?
                                    <AsyncSelect 
                                      cacheOptions
                                      isDisabled={ line.disabled || line.deleted ? true : false }
                                      value={props.getOptions(line.productId, "sizeOptions").filter( item => item.value === line.sizeId )}
                                      defaultOptions={ props.getOptions(line.productId, "sizeOptions") }
                                      onChange={(item) => props.changeValue(i, "sizeId", item.value ) }
                                    />
                                  :
                                    <div key={"EmptySize_"+i} style={{"paddingTop":"1em", "textAlign":"center"}}>N/A</div>
                                }
                              </Col>
                              <Col s={4} className="no-padding">
                                { 
                                  ( props.getOptions(line.productId, "colorOptions").length > 1 ) ?
                                    <AsyncSelect 
                                      cacheOptions
                                      isDisabled={ line.disabled || line.deleted ? true : false }
                                      value={props.getOptions(line.productId, "colorOptions").filter( item => item.value === line.colorId )}
                                      defaultOptions={ props.getOptions(line.productId, "colorOptions") }
                                      onChange={(item) => props.changeValue(i, "colorId", item.value ) }
                                    />
                                  :
                                    <div key={"EmptyColor_"+i} style={{"paddingTop":"1em", "textAlign":"center"}}>N/A</div>
                                }
                              </Col>
                              <Col s={2} className="no-padding">
                                <input 
                                  disabled ={line.deleted || line.is_service  ? true : false }
                                  value={line.qtyOrd} 
                                  type="number" 
                                  onChange={ (e) => {
                                    props.changeValue(i, "qtyOrd", e.target.value );
                                  } } />
                              </Col>
                              <Col s={3} className="no-padding">
                                {
                                  ( props.getOptions(line.productId, "priceOptions").length > 1 )
                                  ?
                                    <AsyncSelect 
                                      isDisabled={ line.disabled || line.deleted ? true : false }
                                      value={ props.getOptions(line.productId, "priceOptions").filter( item => parseFloat(item.value).toFixed(2) === parseFloat(line.price).toFixed(2)) }
                                      defaultOptions={ props.getOptions(line.productId, "priceOptions") }
                                      onChange={(item) => props.changeValue(i, "price", item.value ) }
                                    />
                                  :
                                    <div className="padding-top">{line.price}</div>
                                }
                              </Col>
                            </Row>
                            <Row key={"Existences_"+i}>
                              {
                                props.getOptions(line.productId, "existences").map( existences => {
                                  return (
                                      <Col s={3} key={"Existence_"+existences.location} className="no-padding">
                                        { existences.location } : { existences.stock } 
                                      </Col>
                                  )
                                })
                              }
                            </Row>
                          </td>
                          <td>
                            { ( line.deleted ) ? "0.00" : parseFloat(parseFloat(line.price)*line.qtyOrd).toFixed(2)}
                          </td>
                          <td>
                            <div style={{display:"flex"}}>
                              {
                                <Checkbox value="" label=""  type="checkbox" onChange={ (e) => {
                                  if ( e.target.checked )
                                  {
                                    props.changeValue(i, "status", 'D');
                                  }
                                  else
                                  {
                                    props.changeValue(i, "status", 'U');
                                  }
                                }}
                              />}
                              { ( line.disabled ) ? <Preloader flashing size='small' /> : null }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan ="2">
                    </td>
                    <td>
                      Subtotal:
                    </td>
                    <td>
                      ${subtotal.toFixed(2)}
                    </td>
                    <td>
                      IVA:
                    </td>
                    <td>
                      ${(subtotal*0.16).toFixed(2)}
                    </td>
                    <td>
                      Total:
                    </td>
                    <td>
                      ${(subtotal*1.16).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan ="6">
                      <Button
                        node="button"
                        waves="light"
                        style={{ margin: '0 1%' }}
                        onClick={() => {
                          props.addNew()
                        }}>
                        Agregar
                      </Button>
                    </td>
                    <td colSpan ="2">
                      <Button
                        node="button"
                        waves="light"
                        style={{ margin: '0 1%' }}
                        onClick={() => {
                          props.send()
                        }}>
                        Colocar Pedido
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
export { ModalPedidosEdit as default };
