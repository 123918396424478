import { Button, Col, Container, Row, TextInput, Switch } from 'react-materialize';
import LoadingView from '../loading/loading.view';
import M from 'materialize-css';
import Modal from './modal';
import React from 'react';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import { ErpConnect as qad } from '../../../services';
import './modal.css';

class ModalNewCustomer extends React.Component {
  state = {
    loading: false,
    currentCustomer: null,
    shipOnStore: false,
    isCompany: false,
    haveFiscalData: false,
    shipAddressHasFiscal: false,
    name: '',
    lastname: '',
    lastnameTwo: '',
    email: '',
    phone: '',
    phoneTwo: '',
    zipcode: '',
    zipcodeShipping: '',
    address: '',
    addressShipping: '',
    rfc: '',
    cities: [],
    citiesShipping: [],
    countryOption: null,
    countryShippingOption: null,
    stateOption: null,
    stateShippingOption: null,
    cityOption: null,
    cityShippingOption: null,
    neighboroodOption: null,
    neighboroodShippingOption: null,
    neighborhoods: [],
    neighborhoodsShipping: [],
    clientsRelated: [],
    customerTypes: [],
    isInactive: false
  };

  async componentDidMount() {
  }

  handleChange = (e) => {
    this.setState({
      numCustomer: e.target.value,
    });
  };

  handleCloseModal = () => {
    this.setState({
      loading: false,
      currentCustomer: null,
      isInactive: false,
      shipOnStore: false,
      isCompany: false,
      haveFiscalData: false,
      shipAddressHasFiscal: false,
      name: '',
      lastname: '',
      lastnameTwo: '',
      email: '',
      phone: '',
      phoneTwo: '',
      zipcode: '',
      zipcodeShipping: '',
      address: '',
      addressShipping: '',
      rfc: '',
      cities: [],
      citiesShipping: [],
      countryOption: null,
      countryShippingOption: null,
      stateOption: null,
      stateShippingOption: null,
      cityOption: null,
      cityShippingOption: null,
      neighboroodOption: null,
      neighboroodShippingOption: null,
      priceListOption: null,
      neighborhoods: [],
      neighborhoodsShipping: [],
      clientsRelated: [],
      customerTypes: [],
      isInactive: false
    });
    this.props.onClose();
  };

  handleCreateCustomer = async () => {
    this.setName( this.state );
    let validate = false;
    if ( this.state.isCompany )
    {
      if ( this.state.name !== "" && this.state.email !== "" && this.state.phone != "" )
      {
        validate = true;
      }
      else
      {
        Swal.fire({
          title: 'La empresa debe tener un nombre, un email y un telefono obligatoriamente'
        });
        return;
      }
    }
    else
    {
      if ( this.state.name !== "" && this.state.lastname !== "" && this.state.lastnameTwo != "" && this.state.email !== "" )
      {
        validate = true;
      }
      else
      {
        Swal.fire({
          title: 'El cliente debe tener un nombre, apellido paterno, apellido materno y e-mail'
        });
        return;
      }
    }
    if ( !this.state.customerTypes || this.state.customerTypes.length <= 0 )
    {
      Swal.fire({
          title: 'El cliente debe tener tipo seleccionado'
        });
        return;
    }
    const emailValidator = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
    
    if ( this.state.email !== "" && !emailValidator.test( this.state.email) )
    {
      Swal.fire({
        title: 'El cliente debe tener un e-mail bien formado (ej. nombre@dominio.{extension})'
      });
      return;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    //console.log(user);
    let customerData = {
      extId: Date.now(),
      name : this.state.name + ( ( !this.state.isCompany ) ? " " + this.state.lastname + " " + this.state.lastnameTwo : "" ),
      email: this.state.email,
      phone: this.state.phone ? this.state.phone : "",
      tel2: this.state.phoneTwo ? this.state.phone : "",
      customerType: ( !this.state.customerTypes ) ? "" : this.state.customerTypes.map( (type) =>  type.value ).join(','),
      country: "",
      state: "",
      city: "",
      neighborhood: "",
      municipality: "",
      zipcode: "",
      address: "",
      billingCountry: "",
      billingState: "",
      billingCity: "",
      billingNeighborhood: "",
      billingMunicipality: "",
      billingZipcode: "",
      billingAddress: "",
      billingRfc: "XAXX010101000",
      isCompany: this.state.isCompany,
      pricesList: (!this.state.priceListOption) ? "MENUDEO" : this.state.priceListOption.value,
      distributionChannel: user.salesPersonId,
      isInactive: true
    };

    if ( this.state.haveFiscalData )
    {
      if ( (this.state.countryOption 
              && this.state.stateOption 
              && this.state.cityOption 
              && this.state.neighborhoodOption
              && this.state.zipcode !== ""
              && this.state.address !== ""
              && this.state.rfc !== "" )
            ||
            this.state.rfc !== "" && this.state.currentCustomer != null
        )
      {
        const rfcValidator = new RegExp("^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$");
        const zipcodeValidator = new RegExp("^[0-9]{5}$");
        if ( !this.state.currentCustomer )
        {
          if ( rfcValidator.test(this.state.rfc) && zipcodeValidator.test(this.state.zipcode)  )
          {
            validate = true;
          }
          else
          {
            Swal.fire({
              title: 'El cliente debe tener un codigo postal y un RFC valido'
            });
            return;
          }
        }
        else
        {
          if ( rfcValidator.test(this.state.rfc)  )
          {
            validate = true;
          }
          else
          {
            Swal.fire({
              title: 'El cliente debe tener un RFC valido'
            });
            return;
          }
        }
        if ( !this.state.currentCustomer )
        {
          customerData["country"] = this.state.countryOption.label;
          customerData["state"] = this.state.stateOption.label;
          customerData["city"] = this.state.cityOption.label;
          customerData["neighborhood"] = this.state.neighborhoodOption.label.substring(0,20);
          customerData["municipality"] = this.state.neighborhoodOption.label.substring(0,20);
          customerData["zipcode"] = this.state.zipcode;
          customerData["address"] = this.state.address;
          customerData["billingCountry"] = this.state.countryOption.label;
          customerData["billingState"] = this.state.stateOption.label;
          customerData["billingCity"] = this.state.cityOption.label;
          customerData["billingNeighborhood"] = this.state.neighborhoodOption.label.substring(0,20);
          customerData["billingMunicipality"] = this.state.neighborhoodOption.label.substring(0,20);
          customerData["billingZipcode"] = this.state.zipcode;
          customerData["billingAddress"] = this.state.address;
        }
        customerData["billingRfc"] = this.state.rfc;
        if( this.currentCustomer )
        {
         const clientsWithSameRfc = this.clients.filter((cl) => {
            return cl.billingRfc.toUpperCase() === this.state.rfc.toUpperCase(); 
          });
          if ( clientsWithSameRfc.length > 0 )
          {
            Swal.fire({
              title: 'Ya existe el cliente con RFC: ' + this.state.rfc
            });
            return;
          }
        }
      }
      else
      {
        Swal.fire({
          title: 'El cliente debe tener una ciudad, un estado, un pais, una dirección, un codigo postal y un RFC'
        });
        return;
      }
    }

    if ( this.clients.length <= 0 )
    {
      Swal.fire({
        title: 'Espere a que se carguen los clientes'
      });
      return;
    }

    if( !this.state.shipAddressHasFiscal )
    {
      if ( !this.state.shipOnStore )
      {
        if ( this.state.countryShippingOption 
          && this.state.stateShippingOption 
          && this.state.cityShippingOption 
          && this.state.neighborhoodShippingOption
          && this.state.zipcodeShipping !== ""
          && this.state.addressShipping !== ""
          )
        {
          const zipcodeShippingValidator = new RegExp("^[0-9]{5}$");
          if ( zipcodeShippingValidator.test(this.state.zipcodeShipping) )
          {
            validate = true;
          }
          else
          {
            Swal.fire({
              title: 'El cliente debe tener un codigo postal para envio valido'
            });
            return;
          }
          customerData["country"] = this.state.countryShippingOption.label;
          customerData["state"] = this.state.stateShippingOption.label;
          customerData["city"] = this.state.cityShippingOption.label;
          customerData["neighborhood"] = this.state.neighborhoodShippingOption.label.substring(0,20);
          customerData["municipality"] = this.state.neighborhoodShippingOption.label.substring(0,20);
          customerData["zipCode"] = this.state.zipcodeShipping;
          customerData["address"] = this.state.addressShipping;
          if ( !customerData["billingCountry"] )
          {
            customerData["billingCountry"] = this.state.countryShippingOption.label;
            customerData["billingState"] = this.state.stateShippingOption.label;
            customerData["billingCity"] = this.state.cityShippingOption.label;
            customerData["billingNeighborhood"] = this.state.neighborhoodShippingOption.label.substring(0,20);
            customerData["billingMunicipality"] = this.state.neighborhoodShippingOption.label.substring(0,20);
            customerData["billingZipCode"] = this.state.zipcodeShipping;
            customerData["billingAddress"] = this.state.addressShipping;  
          }
        }
        else
        {
          Swal.fire({
            title: 'El cliente debe tener una ciudad, un estado, un pais, una dirección, un codigo postal para el envio'
          });
          return;
        }
      }
    }
    if ( validate )
    {
      Swal.fire({
        title: ( (!this.state.currentCustomer) ? "Creando" : "Modificando" ) + " cliente",
        showCloseButton: false,
        allowOutsideClick: () => !Swal.isLoading(),
        onOpen: () => {
          Swal.showLoading();
        }
      });
      if ( this.state.currentCustomer )
      {
        customerData["isInactive"] = this.state.isInactive
        const client = await qad.catalog.customer.updateBasic(
          this.state.currentCustomer.value, customerData
        );
        Swal.close();
        if ( client )
        {
          customerData["id"] = this.state.currentCustomer.value;
          this.setState({
            numCustomer: customerData["id"]
          })
          //this.clients.push(customerData);
          const indexClient = this.props.catalog.customers.findIndex( (cl) => cl.id === this.state.currentCustomer.value );
          const indexInactive = this.props.catalog.inactiveCustomers.findIndex( (cl) => cl.id === this.state.currentCustomer.value );
          if ( indexClient !== -1 )
          {
            this.props.catalog.customers[indexClient] = customerData;
          }
          else
          {
            this.props.catalog.customers.push(customerData);
          }
          if ( indexInactive !== -1 )
          {
            this.props.catalog.inactiveCustomers.splice(indexInactive, 1);
          }
          this.handleCloseModal();
          M.toast({
            html:  `Se Actualizo el cliente con ID: ${customerData["id"]}`,
            classes: 'message-danger',
          });
        }
        else
        {
          M.toast({
            html: 'No se actualizó el cliente',
            classes: 'message-danger',
          });
        }
      }
      else
      {
        //console.error("VALIDACION CORRECTA", customerData);
        const client = await qad.catalog.customer.create(
          customerData
        );
        Swal.close();
        if ( client )
        {
          console.log(client);
          customerData["id"] = client.id;
          this.setState({
            numCustomer: client.id
          })
          this.props.catalog.customers.push(customerData);
          this.handleCloseModal();
          M.toast({
            html: `se creo cliente con ID: ${client.id}`,
            classes: 'message-danger',
          });
        }
        else
        {
          M.toast({
            html: 'No se creó el cliente',
            classes: 'message-danger',
          });
        }
      }
    }
    else
    {
      M.toast({
        html: 'No se creó el cliente',
        classes: 'message-danger',
      });
    }
  };

  filterStates = (inputValue) => {
    if ( inputValue.length > 2 )
    {
      this.filtredStates = this.states.filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    else
    {
      this.filtredStates = this.states.slice(0, 500).filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    return this.filtredStates;
  }

  promiseStates = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterStates(inputValue));
    });
  }

  filterCountries = (inputValue) => {
    if ( inputValue.length > 2 )
    {
      this.filtredCountries = this.countries.filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    else
    {
      this.filtredCountries = this.countries.slice(0, 500).filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    return this.filtredCountries;
  }

  promiseCountries = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterCountries(inputValue));
    });
  }

  filterCities = (inputValue) => {
    let filtredCities = [];
    if ( inputValue.length > 2 )
    {
      filtredCities = this.state.cities.filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    else
    {
      filtredCities = this.state.cities.slice(0, 500).filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    return filtredCities;
  }

  promiseCities = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterCities(inputValue));
    });
  }

  filterNeighborhoods = (inputValue) => {
    let filtred = [];
    if ( !this.state.isOnShipping )
    {
      if ( inputValue.length > 2 )
      {
        filtred = this.state.neighborhoods.filter((i) =>{
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        );
      }
      else
      {
        filtred = this.state.neighborhoods.slice(0, 500).filter((i) =>{
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        );
      }
    }
    else
    {
      if ( inputValue.length > 2 )
      {
        filtred = this.state.neighborhoodsShipping.filter((i) =>{
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        );
      }
      else
      {
        filtred = this.state.neighborhoodsShipping.slice(0, 500).filter((i) =>{
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        );
      }
    }
    return filtred;
  }

  promiseNeighborhoods = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterNeighborhoods(inputValue));
    });
  }

  assignCountry = ( value, isShipping = false ) => 
  {
    if ( value )
    {
      if ( !isShipping )
      {
        this.setState({ 
          countryOption: value
        });
      }
      else
      {
        this.setState({ 
          countryShippingOption: value 
        });
      }
    }
    else
    {
      if ( !isShipping )
      {
        this.setState({
          countryOption: value
        });
      }
      else
      {
        this.setState({
          countryShippingOption: value
        });
      }
    }
  }

  assignState = (value, isShipping = false) =>
  {
    if ( !value )
    {
      if (isShipping)
      {
        this.setState({ 
          stateOption: value
        });
      }
      else
      {
        this.setState({ 
          stateShippingOption: value
        });
      }
      return;
    }
    if(!isShipping) 
    {
      this.setState({
        stateOption: value,
        cities : this.fullCities.filter( (item) => item.stateId === value.value ),
        cityOption: null
      });
    }
    else
    {
      this.setState({
        stateShippingOption : value,
        citiesShipping: this.fullCities.filter( (item) => item.stateId === value.value ),
        cityShippingOption: null
      });
    }
  }

  assignCities = async (value, isShipping = false) =>
  {
    if ( !value )
    {
      if ( !isShipping )
      {
        this.setState({
          cityOption: null,
          neighborhoodOption: null,
          zipcode: ""
        });
      }
      else
      {
        this.setState({
          cityShippingOption: null,
          neighborhoodShippingOption: null,
          zipcodeShipping: ""
        });
      }
      return;
    }
    Swal.fire({
      title: "Cargando Colonias",
      showCloseButton: false,
      allowOutsideClick: () => !Swal.isLoading(),
      onOpen: () => {
        Swal.showLoading();
      }
    });
    const neighborhoods = await qad.catalog.website.getNeighborhoods(
      value.value
    );
    if ( !isShipping )
    {
      this.setState({
        cityOption: value,
        neighborhoods: neighborhoods.map((item) => {return { label: item.label+" ( "+item.zip+" )", value: item.zip, zipcode: item.zip} }),
        neighborhoodOption: null,
        zipcode: ""
      });
    }
    else
    {
      this.setState({
        cityShippingOption: value,
        neighborhoodsShipping: neighborhoods.map((item) => {return { label: item.label+" ( "+item.zip+" )", value: item.zip, zipcode: item.zip} }),
        neighborhoodShippingOption: null,
        zipcodeShipping: ""
      });
    }
    Swal.close();
  }

  assignNeigborhoods = async (value, isShipping = false) =>
  {
    if ( !value )
    {
      if ( !isShipping )
      {
        this.setState({ 
          neighborhoodOption: null
        });
      }
      else
      {
        this.setState({ 
          neighborhoodShippingOption: null
        });
      }
      return;
    }
    if ( !isShipping )
    {
      this.setState({
        neighborhoodOption: value,
        zipcode: value.zipcode
      });
    }
    else
    {
      this.setState({
        neighborhoodShippingOption: value,
        zipcodeShipping: value.zipcode
      });
    }
  }

  setName = async (datas) =>
  {

    await this.setState(datas);

    let clients = [];
    if ( 
        ( this.state.name !== null  && this.state.name !== "" ) 
        && ( this.state.lastname !== null  && this.state.lastname !== "" ) 
        && ( this.state.lastnameTwo !== null  && this.state.lastnameTwo !== "" )
      )
    {
      clients = this.clients.filter( (client) => {
        return ( client.name.toLowerCase().includes(this.state.name.toLowerCase()) 
          && client.name.toLowerCase().includes(this.state.lastname.toLowerCase()) 
          && client.name.toLowerCase().includes(this.state.lastnameTwo.toLowerCase()) );
      });
    }
    if ( clients.length < 3 )
    {
      if ( ( this.state.name !== null  && this.state.name !== "" ) && ( this.state.lastname !== null  && this.state.lastname !== "" ) )
      {
        clients = [ ...clients,  ...this.clients.filter( (client) => {
            return ( client.name.toLowerCase().includes(this.state.name.toLowerCase()) && client.name.toLowerCase().includes(this.state.lastname.toLowerCase()) );
          })];
      }
    }
    if ( clients.length < 3 )
    {
      if ( this.state.name !== null  && this.state.name !== "" )
      {
        clients = [ ...clients, ...this.clients.filter( (client) => {
            return client.name.toLowerCase().includes(this.state.name.toLowerCase());
          })];
      }
    }
    this.setState({
      clientsRelated: clients.splice(0, 3)
    })
  }

  filterCustomers = (inputValue) => {
    console.log(inputValue, inputValue.length);
    if ( inputValue.length > 3 )
    {
      this.filtredClients = this.customers.filter((i) =>{
          return i.label.toLowerCase().includes(inputValue.toLowerCase())
        }
      );
    }
    else
    {
      if ( inputValue.length > 3 )
      {
        this.filtredClients = this.customers.filter((i) =>{
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        );
      }
      else
      {
        this.filtredClients = this.customers.slice(0, 500).filter((i) =>{
            return i.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        );
      }
    }
    return this.filtredClients;
  }

  promiseCustomers = (inputValue) => {
    return new Promise ((resolve) => {
      resolve(this.filterCustomers(inputValue));
    });
  }

  selectCustomerToUpdate = (customer) => {
    this.setState({currentCustomer:customer});
    if ( !customer )
    {
      return;
    }
    const client = this.clients.find( (clientF) => {
      return clientF.id === customer.value;
    });
    if (client)
    {
      let name = "";
      let lastname = "";
      let lastnameTwo = "";
      let isCompany = ( !(client.isCompany instanceof String) ) ? false : client.isCompany;
      if ( isCompany )
      {
        name = client.name;
      }
      else
      {
        const clientName = client.name.split(" ");
        if ( clientName.length > 0 )
        {
          if ( clientName.length === 1 )
          {
            name = clientName[0];
            isCompany = true;
          }
          else if ( clientName.length === 2 )
          {
            name = clientName[0];
            lastname = clientName[1];
          }
          else
          {
            lastname = clientName[clientName.length-2];
            lastnameTwo = clientName[clientName.length-1];
            name = clientName.slice( 0, clientName.length-2 ).join(" ");
          }
        }
      }
      //console.log(client, client.customerType.split(","), this.customerTypes);
      const clientTypes = client.customerType.split(",");
      const priceListSelected = this.priceLists.filter( (plist) => plist.value === client.pricesList );
      this.setState({
        haveFiscalData: true,
        shipOnStore: true,
        isCompany: isCompany,
        isInactive: client.isInactive,
        name : name,
        lastname: lastname,
        lastnameTwo: lastnameTwo,
        email: client.email,
        phone: client.phone,
        customerTypes: ( !client.customerType ) ? null : this.customerTypes.filter( (type) =>  clientTypes.indexOf( type.value ) !== -1 ),
        rfc: client.billingRfc.replaceAll("-", ""),
        priceListOption: ( priceListSelected.length > 0 ) ? priceListSelected[0] : this.priceLists[0]
      });
    }
  }

  render() 
  {
    this.countries = this.props.catalog.geography.countries.map( (item) =>  { return { label: item.label, value: item.id }});
    this.states = this.props.catalog.geography.states.map( (item) =>  { return { label: item.label, value: item.id }});
    this.fullCities = this.props.catalog.geography.cities.map( (item) =>  { return { label: item.label, value: item.id, stateId: item.state_id }});
    this.customerTypes = this.props.catalog.customerTypes.map( (item) =>  { return { label: item.name, value: item.id }});
    this.priceLists = [{label: 'Menudeo', value:'MENUDEO'}, {label: 'Mayoreo', value:'MAYOREO'}];
    this.clients = [...this.props.catalog.customers, ...this.props.catalog.inactiveCustomers];
    this.filtredClients = this.props.catalog.customers;
    this.customers = this.clients.map( (el)=> { return {"value": el.id, "label": el.id +" - "+ el.name+ " - " + el.billingRfc, "priceList": el.pricesList } } );

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.handleCloseModal}
        >
          <Container>
            <Row>
              <Col s={12}>
                <h3 className="m3"> { (this.state.currentCustomer != null ) ? "Modificar" :"Crear"} Cliente</h3>
                <Row>
                  <Col s={12} className="margin">
                    <AsyncSelect 
                      placeholder="Clientes" 
                      cacheOptions 
                      defaultOptions 
                      isClearable 
                      value={this.currentCustomer}
                      onChange={(value) => {this.selectCustomerToUpdate(value);}} 
                      defaultOptions={this.customers.slice(0, 500)} 
                      loadOptions={this.promiseCustomers}
                      isLoading={!this.props.catalog.hasInactiveCustomers}
                      loadingMessage={()=> "Se estan cargando los clientes, Espere un momento" }
                      />
                  </Col>
                  <Col className="p-bottom s4 l3 offset-l1 ">
                    <label> ¿Es empresa? </label>
                    <Switch
                      id="isCompanyCustomer"
                      onLabel={'Si'}
                      offLabel={'No'}
                      checked={this.state.isCompany}
                      onChange={(e) => this.setState({ isCompany: e.target.checked}) }
                    />
                  </Col>
                  {
                    ( !this.state.currentCustomer )
                    ?
                      [
                        <Col
                          key="haveFiscalDataCustomerCol"
                          className="p-bottom s4 l3 offset-l1">
                          <label> ¿Requiere Factura? </label>
                          <Switch
                            id="haveFiscalDataCustomer"
                            onLabel={'Si'}
                            offLabel={'No'}
                            checked={this.state.haveFiscalData}
                            onChange={(e) => this.setState({ haveFiscalData: e.target.checked}) }
                          />
                        </Col>
                        ,
                        <Col
                          key="shipOnStoreCustomerCol"  
                          className="p-bottom s4 l3 offset-l1">
                          <label> Entrega en Tienda </label>
                          <Switch
                            id="shipOnStoreCustomer"
                            onLabel={'Si'}
                            offLabel={'No'}
                            checked={this.state.shipOnStore}
                            onChange={(e) => this.setState({ shipOnStore: e.target.checked}) }>
                          </Switch>
                        </Col>
                      ]
                    :
                    <Col className="p-bottom s4 l3 offset-l1">
                      <label> ¿Activar? </label>
                      <Switch
                        id="isInactive"
                        onLabel={'Si'}
                        offLabel={'No'}
                        checked={!this.state.isInactive}
                        onChange={(e) => this.setState({ isInactive: !e.target.checked}) }>
                      </Switch>
                    </Col>
                  }
                  <Col s={12} l={6} className="p-bottom">
                    <TextInput
                      id="nameCustomer"
                      s={12}
                      value={this.state.name}
                      label={ (!this.state.isCompany) ? "Nombre" : "Razón Social" }
                      onChange={ (e)=> this.setName( { name:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.isCompany) ? 'none' : '' }}>
                    <TextInput
                      id="lastnameCustomer"
                      s={12}
                      value={this.state.lastname}
                      label="Apellido Paterno"
                      onChange={ (e)=> this.setName( { lastname:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.isCompany) ? 'none' : '' }}>
                    <TextInput
                      id="lastnametwoCustomer"
                      s={12}
                      value={this.state.lastnameTwo}
                      label="Apellido Materno"
                      onChange={ (e)=> this.setName( { lastnameTwo:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom">
                    <TextInput
                      id="emailCustomer"
                      s={12}
                      type={"email"}
                      value={this.state.email}
                      label="E-mail"
                      onChange={ (e)=> this.setState( { email:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom">
                    <TextInput
                      id="phoneCustomer"
                      s={12}
                      type={"tel"}
                      value={this.state.phone}
                      label="Telefono"
                      onChange={ (e)=> this.setState( { phone:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom">
                    <AsyncSelect
                      id="customerType"
                      placeholder="Tipo de Cliente"
                      cacheOptions
                      isClearable
                      isMulti
                      value={this.state.customerTypes}
                      onChange={(value) => this.setState({customerTypes:value}) }
                      defaultOptions={this.customerTypes} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={ {'display': !this.state.currentCustomer ? '' : 'none' }}>
                    <AsyncSelect
                      id="priceList"
                      placeholder="Lista de precios"
                      cacheOptions
                      isClearable
                      value={this.state.priceListOption}
                      onChange={(value) => this.setState({priceListOption:value}) }
                      defaultOptions={this.priceLists} />
                  </Col>
                  <Col s={12} style={ {'display': this.state.clientsRelated.length > 0 ? '' : 'none' }}>
                    <Row>
                      <Col s={12}>
                        <span s={12}>Clientes en sistema</span>
                      </Col>
                      <Col s={12}>
                        {
                          this.state.clientsRelated.map( (client, idx) => {
                            //console.log(client);
                            return (
                              <React.Fragment key={idx+"_"+client.id}>
                                <Col s={12} l={4}>
                                  <div>
                                    <label>Cliente:&nbsp;</label>
                                    <span>{client.name}, RFC: {client.billingRfc}</span>
                                  </div>
                                </Col>
                              </React.Fragment>
                            )
                          })
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col s={12} style={{'display': (!this.state.haveFiscalData) ? 'none' : '' }}>
                    <h4 s={12}>Datos Fiscales</h4>
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData) ? 'none' : '' }}>
                    <TextInput
                      id="rfcCustomer"
                      s={12}
                      type={"text"}
                      value={this.state.rfc}
                      label="RFC"
                      onChange={ (e)=> this.setState( { rfc: e.target.value.toUpperCase() } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData || this.state.currentCustomer ) ? 'none' : '' }}>
                    <TextInput
                      id="addressCustomer"
                      s={12}
                      value={this.state.address}
                      label="Dirección (Calle)"
                      onChange={ (e)=> this.setState( { address:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData || this.state.currentCustomer ) ? 'none' : '' }}>
                    <AsyncSelect
                      id="countryCustomer"
                      placeholder="Pais"
                      cacheOptions
                      isClearable
                      loadOptions={this.promiseCountries}
                      value={this.countries.length === 1 ? this.state.countryOption = this.countries[0] : this.state.countryOption }
                      onChange={(value) => this.assignCountry(value) }
                      defaultOptions={this.countries} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData || this.state.currentCustomer ) ? 'none' : '' }}>
                    <AsyncSelect
                      id="stateCustomer"
                      placeholder="Estado"
                      cacheOptions
                      isClearable 
                      loadOptions={this.promiseStates}
                      value={this.states.length === 1 ? this.states[0] : this.state.stateOption }
                      onChange={(value) => this.assignState(value) }
                      defaultOptions={this.states} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData || this.state.currentCustomer ) ? 'none' : '' }}>
                    <AsyncSelect
                      id="cityCustomer"
                      placeholder="Ciudad"
                      cacheOptions
                      isClearable
                      loadOptions={this.promiseCities}
                      value={this.state.cityOption}
                      onChange={(value) => this.assignCities(value) }
                      defaultOptions={this.state.cities} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData || this.state.currentCustomer ) ? 'none' : '' }}>
                    <AsyncSelect
                      id="neighboroodCustomer"
                      placeholder="Colonia"
                      cacheOptions
                      isClearable
                      loadOptions={this.promiseNeighborhoods}
                      value={this.state.neighborhoodOption}
                      onFocus={(e)=>  this.setState( {isOnShipping: false} ) }
                      onChange={(value) => this.assignNeigborhoods(value) }
                      defaultOptions={this.state.neighborhoods.slice(0,200)} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (!this.state.haveFiscalData || this.state.currentCustomer ) ? 'none' : '' }}>
                    <TextInput
                      id="zipcodeCustomer"
                      s={12}
                      value={this.state.zipcode}
                      label="Codigo Postal"
                      onChange={ (e)=> this.setState( { zipcode:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} style={{'display': (this.state.shipOnStore) ? 'none' : '' }}>
                    <Row>
                      <Col l={4} m={6} s={7}>
                        <h4>
                          Datos de Envío
                        </h4>
                      </Col>
                      <Col s={3} style={{'display': (!this.state.shipOnStore) ? ( ( !this.state.haveFiscalData ) ? 'none' : '' ) : 'none' }}>
                        <label> ¿Mismos que los Fiscales? </label>
                        <Switch
                          id="shipAddressHasFiscalCustomer"
                          onLabel={'Si'}
                          offLabel={'No'}
                          checked={this.state.shipAddressHasFiscal}
                          onChange={(e) => this.setState({ shipAddressHasFiscal: e.target.checked})}
                        />
                      </Col>
                    </Row>
                  </Col>
                  
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore ) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                    <TextInput
                      id="phoneShipping"
                      s={12}
                      type={"tel"}
                      value={this.state.phoneTwo}
                      label="Contacto de Envío"
                      onChange={ (e)=> this.setState( { phoneTwo:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore ) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                    <TextInput
                      id="addressShippingCustomer"
                      s={12}
                      value={this.state.addressShipping}
                      label="Dirección (Calle)"
                      onChange={ (e)=> this.setState( { addressShipping:e.target.value } ) }
                    />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore ) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                   <AsyncSelect
                      id="countryShippingCustomer"
                      placeholder="Pais"
                      cacheOptions 
                      loadOptions={this.promiseCountries} 
                      value={this.countries.length === 1 ? this.state.countryShippingOption = this.countries[0] : this.state.countryShippingOption }
                      defaultValue={this.countries.length === 1 ? this.countries[0] : null }
                      onChange={(value) => this.assignCountry(value, true ) }
                      defaultOptions={this.countries} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                    <AsyncSelect
                      id="stateShippingCustomer"
                      placeholder="Estado"
                      cacheOptions 
                      loadOptions={this.promiseStates}
                      value={this.state.stateShippingOption}
                      onChange={(value) => this.assignState(value, true) }
                      defaultOptions={this.states} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                    <AsyncSelect
                      id="cityShippingCustomer"
                      placeholder="Ciudad"
                      cacheOptions 
                      loadOptions={this.promiseCities}
                      value={this.state.cityShippingOption}
                      onChange={(value) => this.assignCities(value, true) }
                      defaultOptions={this.state.citiesShipping} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                    <AsyncSelect
                      id="neighboroodShippingCustomer"
                      placeholder="Colonia"
                      cacheOptions
                      isClearable
                      loadOptions={this.promiseNeighborhoods}
                      value={this.state.neighborhoodShippingOption}
                      onFocus={(e)=>  this.setState( { isOnShipping: true } ) }
                      onChange={(value) => this.assignNeigborhoods(value, true) }
                      defaultOptions={this.state.neighborhoodsShipping.slice(0,200)} />
                  </Col>
                  <Col s={12} l={6} className="p-bottom" style={{'display': (this.state.shipOnStore) ? 'none' : ( ( this.state.haveFiscalData && this.state.shipAddressHasFiscal ) ? 'none' : '' ) }}>
                    <TextInput
                      id="zipcodeShippingCustomer"
                      s={12}
                      value={this.state.zipcodeShipping}
                      label="Codigo Postal"
                      onChange={ (e)=> this.setState( { zipcodeShipping:e.target.value } ) }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="m3"
                  node="button"
                  waves="light"
                  style={{zIndex:0}}
                  onClick={this.handleCreateCustomer}
                >
                  { ( this.state.currentCustomer ) ? "Actualizar" : "Crear"} Cliente
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </React.Fragment>
    );
  }
}
export { ModalNewCustomer as default };

/*"distributionChannel": "PAGINA",
"typeId": "PISO",
"pricesList": "MENUDEO",
"address": "PASEO TOLLOCAN SANTA MARIATOTLTEPEC",
"neighborhood": "",
"city": "TOLUCA",
"state": "MÉXICO (EDO MÉX)",
"zipCode": "50200",
"country": "MEXICO",
"municipality": "TOLTEPEC",
"billingBussinessName": "",
"billingRfc": "IULR9201128RT",
"billingUsoCfdi": "S01",*/