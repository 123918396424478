import { ErpResponseError } from '../errors/index.js';
import omit from 'just-omit';
import config from './../../../erpconfig.js';
import Swal from 'sweetalert2';

/**
 * Format connection response
 * @param {object} response
 * @return {Object[]}
 */
function formatResponse(response, preventError = false) {
  //console.log("formatResponse", response);
  if (response !== undefined) {
    if (response.config.url.includes('relatedProduct')) {
      const { read } = getResultResponse(response, preventError);
      return read;
    }
    const { data } = getResultResponse(response, preventError);
    return data;
  }
  return [];
}

/**
 * Get request.
 * @param {Axios} client
 * @param {string} apiEndpoint
 * @param {Object} [options={}]
 * @param {Object} [options.params={}]
 * @param {Object} [options.paths={}]
 * @return {Promise<Object[]>}
 */
async function get(client, apiEndpoint, options = {}, preventThrow = false) {
  const { paths = [] } = options;

  const uri = `${apiEndpoint}${paths.join('/')}`;

  const requestConfig = omit(options, ['paths']);

  const response = await client.get(uri, requestConfig).catch((err) => {
    //throw new ErpResponseError(err.response.data.status_txt);
    throwError(err, preventThrow);
  });
  if (checkIfErrorResponse(response)) {
    return getErrorResponse(response, preventThrow);
  }

  return response;
}

/**
 * Post request.
 * @param {Axios} client
 * @param {string} apiEndpoint
 * @param {Object} data
 * @param {Object} [options={}]
 * @param {Object} [options.params={}]
 * @param {Object} [options.paths={}]
 * @return {Promise<Object[]>}
 */
async function post(client, apiEndpoint, data, options = {}) {
  const { paths = [] } = options;

  const uri = `${apiEndpoint}${paths.join('/')}`;

  const requestConfig = omit(options, ['paths']);

  const response = await client.post(uri, data, requestConfig).catch((err) => {
    //throw new ErpResponseError(err);
    throwError(err);
  });

  if (checkIfErrorResponse(response)) {
    return getErrorResponse(response);
  }

  return response;
}

/**
 * Put request.
 * @param {Axios} client
 * @param {string} apiEndpoint
 * @param {Object} [data={}]
 * @param {Object} [options={}]
 * @param {Object} [options.params={}]
 * @param {Object} [options.paths={}]
 * @return {Promise<Object[]>}
 */
async function put(client, apiEndpoint, data = {}, options = {}) {
  const { paths = [] } = options;

  const uri = `${apiEndpoint}${paths.join('/')}`;

  const requestConfig = omit(options, ['paths']);

  const response = await client.put(uri, data, requestConfig).catch((err) => {
    //throw new ErpResponseError(err.response.data.status_txt);
    throwError(err);
  });

  if (checkIfErrorResponse(response)) {
    return getErrorResponse(response);
  }

  return response;
}

/**
 * Delete request.
 * @param {Axios} client
 * @param {string} apiEndpoint
 * @param {Object} [options={}]
 * @param {Object} [options.params={}]
 * @param {Object} [options.paths={}]
 * @return {Promise<Object[]>}
 */
async function del(client, apiEndpoint, options = {}) {
  const { paths = [] } = options;

  const uri = `${apiEndpoint}${paths.join('/')}`;

  const requestConfig = omit(options, ['paths']);

  const response = await client.delete(uri, requestConfig).catch((err) => {
    //throw new ErpResponseError(err.response.data.status_txt);
    throwError(err);
  });

  if (checkIfErrorResponse(response)) {
    return getErrorResponse(response);
  }

  return response;
}

/**
 * Check if passed response has passed header name.
 * @param {Object} response
 * @return {boolean}
 */
function checkIfErrorResponse(response) {
  const { status } = getResultResponse(response);
  if (status.toUpperCase() === 'ERROR' ) {
    return true;
  }
  return false;
}

/**
 * Gets error object of passed response.
 * @param {Object} response
 * @return {Object}
 */
function getErrorResponse(response, preventThrow = false) {
  console.log("getErrorResponse", response, preventThrow);
  try
  {
    const { message } = getResultResponse(response);
    if ( !preventThrow )
    {
      throwError(message, preventThrow);
    }
  }
  catch ( error )
  {
    try {
      const { description } = getResultResponse(response);
      if ( !preventThrow ) {
        throwError(description, preventThrow);
      }
    }
    catch ( otherError )
    {
      throw new ErpResponseError("Undetermined Error");  
    }
  }
  //throw new ErpResponseError(message);
}

function throwError(message, preventError = false) {
  console.log("throwError",  (typeof message === 'string' || message instanceof String)  , message);
  if( !( typeof message === 'string' || message instanceof String ) )
  {
    const err = message;
    message = "Check api error response: \n";
    if ( message === undefined )
    {
      message = "Error-on-Api undefined throwError ";
    }
    else if ( err["response"] && err.response )
    {
      if ( err.response.statusText ) {
        message += " " + err.response.statusText;
      }
      try 
      {
        if ( err.response.data.status_txt ) {
        message += " " + err.response.data.status_txt;
        }
        if ( err.response.data._errors ) {
          message += " response: "+JSON.stringify(err.response.data._errors);
          message += " request: "+JSON.stringify(err.config.params);
        }
      }
      catch ( error )
      {
        message += JSON.stringify(error);
      }
    }
    else
    {
      message += JSON.stringify(err);
    }
  }
  if ( !preventError )
  {
    printError( message, "Api Error:");
  }
}

/**
 * Gets result of passed response
 * @param {Object} response
 * @return {Object}
 */
function getResultResponse(response, preventError = false ) {
  //console.log( "getResultResponse", response);
  if ( response !== undefined ) {
    try {
      const { data } = response;
      const [ result ] = Object.values(data);
      //console.log( "getResultResponse result", result[0]);
      return result[0];
    }
    catch ( error )
    {
      if ( preventError )
      {
        console.log("prevent");
        return { "status": "FAIL", "description" : JSON.stringify(error), "data": [], "error": true };
      }
      else
      {
        printError( JSON.stringify(error), "Api Error: getResultResponse");
      }
    }
  }
  return { "status": "FAIL", "description" :  "Api Error: empty", "data": [], "error": true };
}

/**
 * Gets endpoint to the configured api
 * @return String
 */
function getApiEndpoint(){
  return config.apiBaseUrl;
}

/**
 * Gets endpoint to the configured api
 * @return String
 */
function getWebsiteEndpoint(){
  if ( config.websiteUrl === undefined )
  {
    return 'http://stg.miplayera.com.mx/'
  }
  return config.websiteUrl;
}

/**
 * Gets result of passed response
 * @param String response
 * @return {Object}
 */
function transformApiUrl(currentEndpoint){
  if ( config.useAlternativeUrl )
  {
    return currentEndpoint.replace("gmpPlatforms", "apisMiPla");
  };
  return currentEndpoint;
}

function printError(message, from = "Api Error: Anywhare")
{
  Swal.fire({
    title: from,
    text: message,
    reverseButtons: true,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Aceptar',
  });
}

export {
  checkIfErrorResponse,
  del,
  formatResponse,
  get,
  getErrorResponse,
  getResultResponse,
  post,
  put,
  getApiEndpoint,
  transformApiUrl,
  getWebsiteEndpoint,
  printError
};
