import { formatResponse, get, getApiEndpoint } from '../helpers/index.js';

const LOCATION_AVAILABLE_NAME = {
  ALMACENES: 'ALMACENES',
  MEXICO: 'MÉXICO',
  MEXICOBO: 'MÉXICOBO',
  MONTERREY: 'MONTERREY',
  XLLEGAR: 'XLLEGAR',
};

const LOCATIONS_AVAILABLE = {
  [`${LOCATION_AVAILABLE_NAME.MONTERREY}`]: [
    {
      site: '3000',
      location: 'MTY',
    },
  ],
  [`${LOCATION_AVAILABLE_NAME.MEXICO}`]: [
    {
      site: '3100',
      location: 'MEX',
    }
  ],
  [`${LOCATION_AVAILABLE_NAME.MEXICOBO}`]: [
    {
      site: '3100',
      location: 'MEXBO',
    },
  ],
  [`${LOCATION_AVAILABLE_NAME.ALMACENES}`]: [
    {
      site: '3000',
      location: 'GILDAN',
    },
    {
      site: '3000',
      location: 'MTYIMP',
    },
    {
      site: '3000',
      location: 'YELOS',
    },
  ],
  [`${LOCATION_AVAILABLE_NAME.XLLEGAR}`]: [
    {
      site: '3000',
      location: 'XLLEGAR',
    },
  ],
};

class LocationService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'location';
    this.LOCATIONS_AVAILABLE = LOCATIONS_AVAILABLE;
  }

  async all() {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { site: '', loc: '' },
    });
    return formatResponse(getResponse);
  }

  async findBySite(site) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { site, loc: '' },
    });
    return formatResponse(getResponse);
  }

  async findByLocation(loc) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { site: '', loc },
    });
    const [location] = formatResponse(getResponse);
    return location;
  }

  async findBySiteAndLocation(site, loc) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { site, loc },
    });
    const [location] = formatResponse(getResponse);
    return location;
  }
}

export { LocationService as default };
