import './product.css';

import { Button, Card, Col, Row, TextInput, Switch } from 'react-materialize';

import Config from '../../../config';
import FileService from '../../../services/file.service';
import MarcasRelacionadasView from './mrelacionados.view';
import ProductosRelacionadosView from './prelacionados.view';
import React from 'react';
import imageNotAvailable from './../../../assets/images/imagenotavailable.png';
import Loader from '../loader/loader.view';
import Swal from 'sweetalert2';

function includesNumber(colorid) {
  for (let i = 0; i < 10; i++) {
    if (colorid.includes(String(i))) {
      return true;
    }
  }
  return false;
}

class ProductView extends React.Component {
  // estado
  state = {
    productosRelacionados: null,
    marcasRelacionadas: null,
    isOpenMarcas: false,
    colores: [],
    filtredColors: [],
    colorBackTitulo: '#fff',
    colorText: null,
    imgBaseRute: Config.filesUrlEspeciales,
    productosTallas: [],
    loadTallasInventory: false,
    tallasPrecios: null,
    currentColor: '',
    canSetItem: false,
    showCodes: false
  };
  fileService = new FileService();

  async componentDidMount() {
    try {
      if ( this.props.product.modelosRelacionados )
      {
        this.props
          .getArticulosRelacionados(
            this.props.product.modelosRelacionados,
            this.props.product.cse_prod
          )
          .then((response) => {
            this.setState({ productosRelacionados: response });
          });
      }

      this.props.getMarcasRelacionadas(this.props.brandId).then((response) => {
        this.setState({ marcasRelacionadas: response });
      });
      await this.getColorPantone();

      this.setState({
        loadTallasInventory: true,
      });

      try {
        let color = this.state.colores[0].id;
        let tallasInventario = await this.props.getTallasExistencias(color);
        console.log(color, tallasInventario);
        this.colocarTallasPrecios(tallasInventario);
        this.state.colores[0].pulse = true;

        this.setState({
          productosTallas: tallasInventario,
          loadTallasInventory: false,
        });
      } catch (e) {
        Swal.fire({
          title: 'Error al traer las existencias',
          html: JSON.stringify(e)
        })
        console.log(e);
      }

      this.setState({
        marcasRelacionadas: [],
      });
    } catch (exception) {
      console.error(exception);
    }
  }

  componentDidUpdate() {
    // console.log(this.state);
  }

  getColorPantone() {
    this.setState({
      colores: this.props.colores,
      filtredColors : [...this.props.colores],
      currentColor: '',
    });
    //console.log("productos", this.props.productos);
    let colores = this.props.colores;
      if (colores.length === 0) {
      // regresando a vista de producto directo de la vista del pedido
      let coloresServicio = this.props.coloresServicio;
      let newColores = [];
      for (let i = 0; i < coloresServicio.length; i++) {
        for (let x = 0; x < this.props.productos.length; x++) {
          if (coloresServicio[i] === this.props.productos[x].color) {
            newColores.push({
              id: this.props.productos[x].color,
              name: this.props.productos[x].colorName,
              pantone: this.props.productos[x].pantone,
            });
            break;
          }
        }
      }
      colores = newColores;
    } else {
      // manejo de excepciones para mostrar codigo de color en circulos
      let desc_prod = this.props.product.desc_prod;
      let isExcept = false;
      let excepList = this.props.exceptColorNewMedList;

      for (let i = 0; i < excepList.length; i++) {
        if (excepList[i] === desc_prod) {
          isExcept = true;
        }
      }

      if (isExcept) {
        for (let i = 0; i < this.props.productos.length; i++) {
          if (this.props.productos[i].color === colores[i].id) {
            colores[i].id = this.props.productos[i].new_med;
          }
        }
      }
    }

    try {
      return new Promise(async (resolve) => {
        console.log("colores", colores);
        for (let i = 0; i < colores.length; i++)
        {
          let _pantones = null;
          let backColor = [];

          if (colores[i].pantone !== undefined)
          {
            if (colores[i].pantone.includes(','))
            {
              _pantones = colores[i].pantone.split(',');
              console.log("pantones", _pantones);
              for (let z = 0; z < _pantones.length; z++)
              {
                let hexColor = this.props.pantones.filter((response) => {
                  return response.id?.toLowerCase() === _pantones[z].toLowerCase();
                });
                console.log("hexadecimal", hexColor);
                backColor = backColor.concat(hexColor);
              }
              let backLinear = 'linear-gradient(90deg, ';
              let backPorcent = 100 / backColor.length;
              for (let y = 0; y < backColor.length; y++) 
              {
                if (y === backColor.length - 1) {
                  backLinear =
                    backLinear +
                    backColor[y].hexadecimal +
                    ' ' +
                    backPorcent +
                    '%' +
                    ')';
                } else {
                  backLinear =
                    backLinear +
                    backColor[y].hexadecimal +
                    ' ' +
                    backPorcent +
                    '%' +
                    ',';
                }
              }
              backColor = backLinear;
              colores[i].hex = backColor;
            } 
            else
            {
              backColor = this.props.pantones.filter(
                (response) => response.id?.toLowerCase() === colores[i].pantone?.toLowerCase()
              );
              if (backColor.length === 1) {
                backColor = backColor[0].hexadecimal;
              } else {
                if (!colores[i].pantone ) 
                {
                  console.error("Color no tiene pantone corregir!!");
                }
                let rutaImg = colores[i].id;
                if (rutaImg) {
                  backColor = `url('${this.props.url}COLORES%2F${rutaImg.toLowerCase().replaceAll(' ', '-')}.png${this.props.urlPass}') center center / 100% 100% no-repeat , url(${imageNotAvailable}) center center / 100% 100% no-repeat`;
                } else {
                  backColor = `url(${imageNotAvailable}) center center / 100% 100% no-repeat`;
                }
              }
              if (backColor === '#000') {
                this.setState({
                  colorBackTitulo: '#ccc',
                  colorTitulo: '#333',
                });
              }
              colores[i].hex = backColor;
            }
          }
        }
        this.setState({
          colores: colores,
          filtredColors: [...colores],
        });
        resolve(true);
      });
    } catch (e) {
      console.log(e);
    }
  }

  colocarSubtotal = (subtotal, color, talla, precio) => {
    let productosTallas = this.state.productosTallas;

    for (let i = 0; i < productosTallas.length; i++) {
      if (
        productosTallas[i].color === color &&
        productosTallas[i].talla === talla
      ) {
        productosTallas[i].prec_set = parseFloat(precio).toFixed(2);
        productosTallas[i].prec_prod = parseFloat(precio).toFixed(2);
        productosTallas[i].subtotal = parseFloat(subtotal).toFixed(2);
      }
    }

    this.setState({
      productosTallas: productosTallas,
    });
  };

  colocarTallasPrecios = (productosTallas) => {
    let tallasPrecios = [];
    let precios = [];

    for (let i = 0; i < productosTallas.length; i++) {
      precios.push(productosTallas[i].prec_prod);
    }
    precios = precios.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    if (precios.length > 1) {
      for (let i = 0; i < precios.length; i++) {
        let productos = productosTallas.filter(
          (response) => response.prec_prod === precios[i]
        );
        if (productos.length > 1) {
          tallasPrecios.push({
            tallas: `${productos[0].talla}-${
              productos[productos.length - 1].talla
            }`,
            precio: precios[i],
          });
        } else {
          tallasPrecios.push({
            tallas: productos[0].talla,
            precio: precios[i],
          });
        }
      }
    }
    this.setState({
      tallasPrecios,
    });
  };

  render() {
    //console.log(this.props.image);
    return (
      <Row>
        <Col xl={12} l={12} m={12} s={12}>
          <Col xl={12} l={12} m={12} s={12}>
            <Card
              id="leo"
              horizontal
              header={
                <React.Fragment>
                  <Col xl={6} l={6} m={6} s={6}>
                    <Row>
                      <img
                        alt={this.props.cve_prod}
                        src={this.props.image}
                        width="100%"
                        height="auto"
                        style={{ maxHeight: '792px' }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.onError = null;
                          currentTarget.src = imageNotAvailable;
                        }}
                      />
                    </Row>
                    <Row>
                      <Switch
                          id="showCodes"
                          onLabel={'Mostrar'}
                          offLabel={'Ocultar'}
                          checked={this.state.showCodes}
                          onChange={(e) => this.setState({ showCodes: e.target.checked}) }
                        />
                    </Row>
                    <Row>
                      <ProductosRelacionadosView
                        imageRute={this.props.imageRute}
                        changeImageRute={this.props.changeImageRute}
                        changeProductViewIds={this.props.changeProductViewIds}
                        onClickElement={this.props.onClickElement}
                        items={this.state.productosRelacionados}
                      />
                    </Row>
                  </Col>
                </React.Fragment>
              }
              actions={[
                <div className="center" key={1}>
                  <Button
                    onClick={(event) => {
                      let producto;
                      producto = [];
                      try{
                        let inputsTallas = document.getElementsByClassName('inputTalla');
                        for (let i = 0; i < inputsTallas.length; i++) {
                          let inputData = String(inputsTallas[i].id).split('-');
                          let talla = inputData[1];
                          let productId = inputData[2];
                          let color = this.props.colorSeleccionado;
                          let cantidad = 0;
                          // obtencion de inventario
                          let foundProductoTalla =
                            this.state.productosTallas.find((response) => {
                              //console.log(response);
                              return response.cve_prod === productId;
                            });
                          //console.log(foundProductoTalla);
                          const inventoryTotal = parseFloat(
                            foundProductoTalla.existencias.reduce(
                              (a, b) => a + (b.stock ? Number(b.stock) : 0),
                              0
                            )
                          );

                          // cantidad de productos
                          //console.log(parseInt(inputsTallas[i].value));
                          if ( parseInt(inputsTallas[i].value) !== NaN ) {
                            cantidad = parseFloat(inputsTallas[i].value);
                          }

                          // edicion de color en caso de tener new_med
                          if (color.includes('-')) {
                            color = color.split('-')[1].trim(' ');
                          }
                          producto.push({
                            cantidad,
                            color,
                            inventario: inventoryTotal,
                            priceList: foundProductoTalla.priceList,
                            productId,
                            talla
                          });
                        }
                        producto = producto.filter(
                          (response) => response.cantidad > 0
                        );

                        if (
                          producto.length !== undefined &&
                          producto.length > 0
                        ) {
                          try {
                            for (let i = 0; i < producto.length; i++) {
                              this.props.insertIntoLocalStorage(
                                producto[i],
                                true
                              );
                            }
                          } 
                          catch (e) 
                          {
                            console.log("error al agregar los productos", e);
                          } finally {
                            // limpiando parametros en la table despues de agregar
                            let inputsTallas =
                              document.getElementsByClassName('inputTalla');
                            let color = this.props.colorSeleccionado;
                            let talla;
                            for (let i = 0; i < inputsTallas.length; i++) {
                              inputsTallas[i].value = null;
                              talla = String(inputsTallas[i].id).split('-')[1];
                            }
                          }
                        }
                        else
                        {
                          Swal.fire({
                              icon: 'warning',
                              title: `Agregar al menos una cantidad`,
                              html: `<div>
                                      <p>No se agrego ningun producto al carrito</p> 
                                    </div>`,
                          });
                        }
                      }
                      catch(error)
                      {
                        console.log("error", error);
                        Swal.fire({
                              icon: 'warning',
                              title: `Existe un error al seleccionar las cantidades Favor de revisar`,
                              html: `<div>
                                      <p>No se agrego ningun producto al carrito</p> 
                                    </div>`,
                          });
                      }
                    }}
                    disabled={!this.state.canSetItem}
                    large
                  >
                    Agregar a Pedido
                  </Button>
                </div>,
              ]}
              className="card-product"
              key={1}
            >
              <Col xl={12} l={12} m={12} s={12}>
                <Row>
                  <div className="product-header">
                    {/* <h6 className='center product-title'>{`${this.props.product.descripcion} - $${this.props.product.prec_prod}`}</h6> */}
                    <h6 className="center product-title" title={`${this.props.product.cve_prod}`}>{`${this.props.product.descripcion}`}</h6>
                    <p className="center">
                      {this.state.tallasPrecios &&
                        this.state.tallasPrecios.map((value, index) => {
                          return (
                            <React.Fragment key={index}>
                              {value.tallas}: {value.precio}&nbsp;&nbsp;
                            </React.Fragment>
                          );
                        })}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xl={12} l={12} m={12} s={12}>
                    {
                      (this.state.loadTallasInventory)
                      ?
                        <Loader/>
                      :
                        <table style={{ margin: '0 auto' }}>
                          <thead style={{}}>
                            <tr>
                              <th>Talla</th>
                              {Object.keys(this.props.locationsAvailable).map(
                                (location) => {
                                  return <th key={location}>{location}</th>;
                                }
                              )}
                              <th>Cantidad</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.productosTallas.map((value, index) => {
                              let precio = this.props.getPriceByPriceListKey(
                                this.props.catalog.priceListType.MENUDEO,
                                value.priceList
                              );
                              const inventoryTotal = parseFloat(
                                value.existencias.reduce(
                                  (a, b) => a + (b.stock ? Number(b.stock) : 0),
                                  0
                                )
                              );
                              return (
                                <React.Fragment key={index}>
                                  <tr title={(value) ? value.cve_prod : '' }>
                                    <td >{value.talla} <div style={{"display":(this.state.showCodes)?"block":"none"}}>{value.cve_prod}</div></td>
                                    {value.existencias.map(({ stock }, index) => {
                                      return <td key={index}>{stock || 0}</td>;
                                    })}
                                    <td>
                                      {inventoryTotal > 0 ? (
                                        <TextInput
                                          className={'inputTalla'}
                                          id={`input-${value.talla}-${value.cve_prod}`}
                                          label="Cantidad"
                                          type="number"
                                          min={1}
                                          onChange={(event) => {
                                            let cantidad = parseInt(
                                              event.target.value
                                            );
                                            if ( typeof cantidad === "number")
                                            {
                                              let subtotal = cantidad * parseFloat(precio);
                                              this.colocarSubtotal(
                                                subtotal,
                                                value.color,
                                                value.talla,
                                                precio
                                              );
                                              this.setState({
                                                canSetItem: true
                                              });
                                            }
                                            else
                                            {
                                              console.log("No se puede agregar cantidad");
                                            }
                                          }}
                                        />
                                      ) : (
                                        <small>No disponible</small>
                                      )}
                                    </td>
                                    <td>
                                      <i
                                        style={{ color: '#333', fontWeight: '500' }}
                                      >
                                        $ {value.subtotal ? value.subtotal : 0}
                                      </i>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                    }
                    <br />
                    <p style={{ textAlign: 'center' }}>
                      <strong>
                        {this.props.colorSeleccionado.toUpperCase()}
                      </strong>
                    </p>
                    <br />
                  </Col>
                </Row>
                <Row>
                  {this.props.colorHidden ? (
                    <div />
                  ) : this.props.existenColores ? (
                    <Row>
                      <Col
                        xl={12}
                        l={12}
                        m={12}
                        s={12}
                        className={
                          this.props.colorId === 'SC'
                            ? 'general-margin hidden'
                            : 'general-margin'
                        }
                      >
                        <Col s ={12}>
                          <TextInput
                            s={12}
                            id={`input-colors`}
                            label="Color"
                            type="text"
                            value={this.state.currentColor}
                            min={1}
                            onChange={(event) => {
                              console.log(this.state.colores);
                              if ( event.target.value )
                              {
                                this.setState({
                                  currentColor: event.target.value,
                                  filtredColors: this.state.colores.filter((item) => item.id.toLowerCase().includes( event.target.value.toLowerCase() ) || item.name && item.name.toLowerCase().includes( event.target.value.toLowerCase() ) )
                                });
                              }
                              else
                              {
                                this.setState({
                                  currentColor: "",
                                  filtredColors: this.state.colores
                                });
                              }
                            }}
                            />
                        </Col>
                        <Col xl={12} l={12} m={12} s={12}>
                          {this.state.filtredColors.map((color, index) => {
                            return (
                              <Col
                                xl={2}
                                l={2}
                                m={3}
                                s={3}
                                onClick={async (event) => {

                                  this.setState({
                                    loadTallasInventory: true,
                                  });

                                  let productosTallas =
                                    await this.props.getTallasExistencias(
                                      color.id
                                    );
                                  this.colocarTallasPrecios(productosTallas);

                                  let colores = this.state.colores;
                                  let textColor = '';
                                  for (
                                    let i = 0;
                                    i < this.props.colores.length;
                                    i++
                                  ) {
                                    if (i === index) {
                                      colores[i].pulse = true;
                                    } else {
                                      colores[i].pulse = false;
                                    }
                                  }
                                  if (!color.id.includes('BLANCO')) {
                                    textColor = '#fff';
                                  } else {
                                    textColor = '#333';
                                  }

                                  // limpiando inputs
                                  let inputs =
                                    document.getElementsByClassName(
                                      'inputTalla'
                                    );
                                  for (let i = 0; i < inputs.length; i++) {
                                    inputs[i].value = null;
                                  }
                                  for (
                                    let i = 0;
                                    i < productosTallas.length;
                                    i++
                                  ) {
                                    productosTallas[i].subtotal = 0;
                                  }

                                  this.setState({
                                    colores: colores,
                                    colorBackTitulo: color.hex
                                      ? color.hex
                                      : '#c3c3c3',
                                    colorText: textColor,
                                    productosTallas: productosTallas,
                                    loadTallasInventory: false,
                                  });
                                }}
                                key={index}
                              >
                                <Row title={ ( color.name ? color.name + " " : "" ) + color.id}>
                                  <Col s={12}>
                                    <div className="center father">
                                      <a
                                        href="#"
                                        className={`btn-floating dimensions ${
                                          color.pulse ? 'pulse' : ''
                                        }`}
                                        attr-title={color.hex }
                                        style={{ background: color.hex }}
                                      >
                                        {!isNaN(color.id) || color.id === 'SC'
                                          ? color.id
                                          : includesNumber(color.id)
                                          ? color.id
                                          : ''}
                                      </a>
                                    </div>
                                  </Col>
                                  <Col title={ ( color.name ? color.name + " " : "" ) + color.id} s={12} style={{textAlign:"center",fontSize:"12px", height: "2em", overflowY: "scroll", overflowX: "hidden", scrollbarWidth: "thin"}} >
                                    { ( color.name ? color.name + " " : "" ) + color.id}
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })}
                        </Col>
                      </Col>
                    </Row>
                  ) : (
                    <Col xl={12} l={12} m={12} s={12}>
                      <br />
                      <h6>Sin Colores</h6>
                      <br />
                    </Col>
                  )}
                </Row>
              </Col>
            </Card>
            <strong
              className="masMarcas"
              style={{
                textAlign: 'left',
                cursor: 'pointer',
                fontSize: '15pt',
                marginBotton: '1%',
              }}
              onClick={() => {
                console.log('ONCLICK + MARCAS');
                this.setState({
                  isOpenMarcas: !this.state.isOpenMarcas,
                });
              }}
            >
              + Mas de esta marca
            </strong>
            {this.state.isOpenMarcas && (
              <MarcasRelacionadasView
                items={this.state.marcasRelacionadas}
                onClickElement={this.props.onClickElement}
                changeProductViewIds={this.props.changeProductViewIds}
              />
            )}
          </Col>
        </Col>
      </Row>
    );
  }
}

export { ProductView as default };
