import { formatResponse, get, post, put, getApiEndpoint, getWebsiteEndpoint, transformApiUrl } from '../helpers/index.js';
import axios from 'axios';

class CustomerService {
  constructor(client) {
    this.client = client;
    this.apiEndpoint = getApiEndpoint()+'customer';
    this.apiEndpoint = transformApiUrl(this.apiEndpoint);
  }

  async all( salesPersonId = "", filterActive = "") {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', email: '', salesPersonId,  filterActive },
    });
    return formatResponse(getResponse);
  }

  async allBasic() {
    const action = 'msread';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint);
    return formatResponse(getResponse);
  }

  async create(data) {
    const action = 'create';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const postResponse = await post(this.client, endpoint, formattedData);
    const [customerCreated] = formatResponse(postResponse);
    return customerCreated;
  }

  async findById(id) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id, email: '', salesPersonId: '', filterActive : ''},
    }, true);
    const [customer] = formatResponse(getResponse);
    return customer;
  }

  async findByEmail(email) {
    const action = 'read';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id: '', email, salesPersonId: '', filterActive : '' },
    });
    const [customer] = formatResponse(getResponse);
    return customer;
  }

  async update(id, data) {
    const action = 'update';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [data],
    };
    const putResponse = await put(this.client, endpoint, formattedData, {
      params: { id },
    });
    const [customerUpdated] = formatResponse(putResponse);
    return customerUpdated;
  }

  //{"updatebasic":[{"name":"RAUL IZQUIERDO linoz","email":"joraulizqli@gmail.com","phone":"8121592757","rfc":"XAXX010101000","customerType":"TC0002","isInactive":false}]}
  async updateBasic(id, data) {
    const action = 'updatebasic';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const formattedData = {
      [action]: [{
        "name": data.name,
        "email": data.email,
        "phone": data.phone,
        "rfc": data.billingRfc,
        "customerType": data.customerType,
        "isInactive": data.isInactive
      }],
    };
    const putResponse = await put(this.client, endpoint, formattedData, {
      params: { id },
    });
    const [customerUpdated] = formatResponse(putResponse);
    return customerUpdated;
  }

  getWhatsappLink() 
  {
    return `${getWebsiteEndpoint()}ajax/orders/showOrder?id=`;
  }

  async getTypes( id = '' ) {
    const action = 'type';
    const endpoint = `${this.apiEndpoint}/${action}`;
    const getResponse = await get(this.client, endpoint, {
      params: { id },
    });
    return formatResponse(getResponse);
  }
}

export { CustomerService as default };
