import './grid-reporte.css';

import { Button, Icon } from 'react-materialize';
import {
  Grid,
  GridColumn,
  GridDetailRow,
  GridNoRecords,
  GridToolbar,
} from '@progress/kendo-react-grid';

import { ExcelExport } from '@progress/kendo-react-excel-export';
import Loader from '../loader/loader.view';
import React from 'react';
import Swal from 'sweetalert2';
import { ErpConnect as qad } from '../../../services';

class classDetalleCantidad extends React.Component {
  render() {
    let cantidad = this.props.dataItem['cantidad'];
    cantidad = Number(cantidad).toFixed(0);
    return <td>{cantidad}</td>;
  }
}
class classDetallePrecioVenta extends React.Component {
  render() {
    let precioVenta = this.props.dataItem['precio de venta'];
    precioVenta = Number(precioVenta).toFixed(2);
    return <td>${precioVenta}</td>;
  }
}
class classDetalleSubTotal extends React.Component {
  render() {
    let subtotal = this.props.dataItem['subtotal'];
    subtotal = Number(subtotal).toFixed(2);
    return <td>${subtotal}</td>;
  }
}
class classDetalleIva extends React.Component {
  render() {
    let iva = this.props.dataItem['iva'];
    iva = parseFloat(iva).toFixed(2);
    return <td>${iva}</td>;
  }
}
class classDetalleTotal extends React.Component {
  render() {
    let total = this.props.dataItem['total'];
    total = Number(total).toFixed(2);
    return <td>${total}</td>;
  }
}

class detailPedido extends GridDetailRow {
  state = {
    numPedido: this.props.dataItem.Pedido,
    detallePedido: null,
    loading: false,
  };

  formatDetail(detail) {
    return {
      linea: detail.line,
      descripcion: detail.productName,
      cantidad: detail.quantity,
      'precio de venta': detail.price,
      descuento: detail.discount,
      subtotal: detail.subtotal,
      iva: detail.tax,
      total: detail.total,
    };
  }

  handleGetDetalleReporte = async () => {
    this.setState({
      loading: true,
    });
    let result = null;
    try {
      result = await qad.catalog.salesOrder.getDetailById(
        this.props.dataItem.salesOrderId
      );

      result = result.map((item) => {
        return this.formatDetail(item);
      });
      this.setState({
        detallePedido: result,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        loading: false,
      });
      return result;
    }
  };

  async componentDidMount() {
    await this.handleGetDetalleReporte();
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.loading ? (
          <Grid data={this.state.detallePedido}>
            <GridColumn field="linea" title="Linea"></GridColumn>
            <GridColumn field="descripcion" title="Descripcion"></GridColumn>
            <GridColumn
              field="cantidad"
              title="Cantidad"
              cell={classDetalleCantidad}
            ></GridColumn>
            <GridColumn
              field="precio de venta"
              title="Precio de venta"
              cell={classDetallePrecioVenta}
            ></GridColumn>
            <GridColumn field="descuento" title="Descuento"></GridColumn>
            <GridColumn
              field="subtotal"
              title="Subtotal"
              cell={classDetalleSubTotal}
            ></GridColumn>
            <GridColumn
              field="iva"
              title="Iva"
              cell={classDetalleIva}
            ></GridColumn>
            <GridColumn
              field="total"
              title="Total"
              cell={classDetalleTotal}
            ></GridColumn>
          </Grid>
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

class classTotal extends React.Component {
  render() {
    let total = this.props.dataItem['total'];
    return <td>${total}</td>;
  }
}
class descuentoGeneral extends React.Component {
  render() {
    let descuento = this.props.dataItem['discount'];
    return <td>{Number(descuento).toFixed(2)}</td>;
  }
}

// TODO: Enabled when email service is passed
// class enviarCorreo extends React.Component {
//   render() {
//     // let clienteService = new ClienteService();
//     let correo = this.props.dataItem.Email;
//     // let nombreCliente = this.props.dataItem.Cliente;
//     return (
//       <td className="btnEnviar">
//         {this.props.dataItem['Estatus de pedido'] !== 'CANCELADO' ? (
//           <Button
//             node="button"
//             waves="light"
//             onClick={async () => {
//               await Swal.fire({
//                 input: 'email',
//                 inputValue: correo ? correo : '',
//                 inputPlaceholder: 'Ingresa un email',
//                 html: `<small>*Correo en el sistema</small>`,
//                 title: 'Enviar pedido por correo?',
//                 reverseButtons: true,
//                 showCancelButton: true,
//                 confirmButtonColor: '#3085d6',
//                 cancelButtonColor: '#d33',
//                 cancelButtonText: 'Cancelar',
//                 confirmButtonText: 'Enviar',
//               }).then(async (response) => {
//                 if (response.isConfirmed) {
//                   // actualizacion de correo en caso de un cambio de email
//                   // if(response.value !== correo && response.value !== '') { // cambio de email
//                   //     await clienteService.updateCorreoCliente(nombreCliente, response.value);
//                   // }
//                   // send email
//                   await handleSendCorreo(
//                     response.value,
//                     this.props.dataItem['Pedido'],
//                     this.props.dataItem['Cliente'],
//                     this.props.dataItem['Vendedor'],
//                     this.props.dataItem['VendedorId'],
//                     this.props.dataItem['Comentarios de pedido'],
//                     this.props.dataItem['DireccionCliente'],
//                     this.props.dataItem['Descuento general'],
//                     this.props.dataItem['Total']
//                   );
//                   Swal.fire({
//                     title: 'Correo enviado',
//                     icon: 'success',
//                   });
//                 }
//               });
//             }}
//           >
//             <Icon>email</Icon>{' '}
//           </Button>
//         ) : (
//           <td>-</td>
//         )}
//       </td>
//     );
//   }
// }

class CustomCellImprimir extends React.Component {
  render() {
    return ( <td>
        {this.props.dataItem['Estatus de pedido'] !== 'CANCELADO' ? (
          <Button
            className="teal lighten-2"
            node="button"
            waves="light"
            onClick={async () => {
              let numeroPedido = this.props.dataItem.salesOrderId;
              try {
                // let pedidoInfo = await pedidoService.pedidoInfo(numeroPedido);
                let order = await qad.catalog.salesOrder.findById(
                  numeroPedido
                );

                this.props.onPrint(
                  order.salesOrderId,
                  order.remisionId,
                  order.customer,
                  order.salesPerson,
                  order.remarks,
                  order.adress,
                  order.orderDate,
                  order.status,
                  order.subTotal,
                  order.discount ? order.discount : 0 ,
                  order.IVA,
                  order.total,
                  order.detail,
                  JSON.parse(localStorage.getItem('user')).name
                );
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <Icon>local_printshop</Icon>
          </Button>
        ) : (
          <p>-</p>
        )}
      </td>
    );
  }
}

class CancelarPedido extends React.Component {
  render() {
    let numPedido = this.props.dataItem.Pedido;
    //let pedidoService = new PedidoService();

    return ( <td className="btnCancelar">
        {this.props.dataItem['Estatus de pedido'] === 'ACTIVO' ? (
          <Button
            className="red lighten-1"
            node="button"
            waves="light"
            onClick={async () => {
              Swal.fire({
                title: '¿Estas seguro?',
                text: 'Vas a cancelar el pedido..',
                icon: 'warning',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Aceptar',
              }).then(async (result) => {
                if (result.value) {
                  // result.value es igual a true cuando se selecciona aceptar
                  let result;
                  try {
                    //result = await pedidoService.cancelPedido(numPedido);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    this.props.onBuscar();
                    Swal.fire('Cancelado!', 'Pedido cancelado.', 'success');
                    return result;
                  }
                }
              });
            }}
          >
            <Icon>cancel</Icon>{' '}
          </Button>
        ) : (
          <td>-</td>
        )}
      </td>
    );
  }
}

/*
class generarPedido extends React.Component {
  render() {
    let numPedido = this.props.dataItem.Pedido;

    return (
      <td>
        <Button
          className="green lighten-1"
          node="button"
          waves="light"
          onClick={async () => {
            Swal.fire({
              title: '¿Estas seguro?',
              text: 'Vas a generar un nuevo pedido con los mismos articulos',
              icon: 'info',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Aceptar',
            }).then(async (result) => {
              if (result.isConfirmed) {
                let detallePedido = [];
                try {
                  detallePedido = await pedidoService.getDetallePedido(
                    numPedido
                  );

                  // checar existencias
                  for (let i = 0; i < detallePedido.length; i++) {
                    let cve_prod = detallePedido[i].cve_prod;
                    let cse_prod = detallePedido[i].cse_prod;
                    let color = detallePedido[i].color;
                    let cantidad = parseInt(detallePedido[i].cantidad);
                    let desc_prod = detallePedido[i].desc_prod;

                    let { existencia } = await getExistencias(
                      cve_prod,
                      cse_prod,
                      color
                    );
                    existencia = parseInt(existencia);

                    // mensaje error
                    if (cantidad > existencia) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Sin existencia',
                        html: `<div>
                                                <p>El siguiente articulo no cuenta con existencias:</p>
                                                <strong>${desc_prod}</strong>
                                                <br>
                                                <i>Existencia actual: ${existencia}</i>
                                            </div>`,
                      }).then(() => {
                        this.setState({ loading: false });
                      });
                      return;
                    }
                  }

                  if (detallePedido.length > 0) {
                    localStorage.setItem(
                      'productosLocalStorage',
                      JSON.stringify(detallePedido)
                    );
                    Swal.fire(
                      'Generado!',
                      'Verifica los articulos en tu pedido.',
                      'success'
                    );
                  }
                } catch (e) {
                  console.log('error: ', e);
                }
              }
            });
          }}
        >
          <Icon>autorenew</Icon>
        </Button>
      </td>
    );
  }
}*/

class GridReporte extends React.Component {
  _export;
  cancelAuth = JSON.parse(localStorage.getItem('user')).cancel_ped; // 1 acceso || 0 sin acceso

  export = () => {
    this._export.save(this.props.data);
  };

  handleExpandChange = (event) => {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  printCell = (props) => (
    <CustomCellImprimir
      {...props}
      catalog={this.props.catalog}
      onPrint={this.props.onPrint}
    />
  );
  cancelCell = (props) => (
    <CancelarPedido {...props} onBuscar={this.props.onBuscar} />
  );

  componentDidMount() {
    console.log({ catalog: this.props.catalog });
  }

  render() {
    return (
      <React.Fragment>
        <ExcelExport ref={(exporter) => (this._export = exporter)}>
          <Grid
            data={this.props.data}
            detail={detailPedido}
            expandField="expanded"
            onExpandChange={this.handleExpandChange}
            resizable
          >
            <GridNoRecords>No se encontraron registros</GridNoRecords>
            <GridToolbar>
              <div className="toolbar">
                <Button node="button" onClick={this.export}>
                  <span className="k-icon k-i-excel"></span> Exportar a Excel
                </Button>
                <section>
                  {this.props.filtros === 0 ? (
                    <p>
                      Ultimos <strong>{this.props.data.length}</strong>{' '}
                      registros
                    </p>
                  ) : (
                    <p>
                      Registros encontrados:{' '}
                      <strong>{this.props.data.length}</strong>
                    </p>
                  )}
                </section>
              </div>
            </GridToolbar>
            <GridColumn
              width="100px"
              field="salesOrderId"
              title="Pedido"
            ></GridColumn>
            <GridColumn
              width="120px"
              field="salesPersonName"
              title="Vendedor"
            ></GridColumn>
            <GridColumn
              width="150px"
              field="customerName"
              title="Cliente"
            ></GridColumn>
            <GridColumn
              width="120px"
              field="salesOrderDate"
              title="Fecha pedido"
            ></GridColumn>
            <GridColumn
              width="110px"
              field="status"
              title="Estatus pedido"
            ></GridColumn>
            <GridColumn
              width="110px"
              field="remissionId"
              title="Remision"
            ></GridColumn>
            <GridColumn
              width="110px"
              field="invoiceId"
              title="Factura"
            ></GridColumn>
            <GridColumn
              width="150px"
              field="total"
              title="Total"
              cell={classTotal}
            ></GridColumn>
            <GridColumn
              width="150px"
              field="remarks"
              title="Comentarios pedido"
            ></GridColumn>
            <GridColumn
              width="150px"
              field="discount"
              title="Descuento general"
              cell={descuentoGeneral}
            ></GridColumn>
            <GridColumn
              width="90px"
              title="Imprimir"
              cell={this.printCell}
            ></GridColumn>
            { /*{(this.cancelAuth == 1) && <GridColumn title="Cancelar" cell={this.cancelCell} ></GridColumn>}
                    <GridColumn title="Generar" cell={generarPedido} ></GridColumn> */}
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }
}
export default GridReporte;
